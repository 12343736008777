<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item class="d-flex justify-center">
        <a
          @click="$router.replace('/v1')"
        >
          <v-img
            :src="imgLogo"
            max-height="120"
            width="120"
            contain
          />
        </a>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  import imgIcon from '../../../../../src/assets/logo-emp.png'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      imgLogo: imgIcon,
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard',
          to: '/v1',
        },
        {
          icon: 'mdi-view-dashboard',
          title: 'Gerência',
          group: '/v1',
          children: [
            { icon: 'mdi-cart', title: 'Dashboard', to: 'dashboad' },
          ],
        },
        {
          icon: 'mdi-arrow-left-right-bold',
          title: 'Transações',
          group: '/v1',
          children: [
            { icon: 'mdi-cart', title: 'Pedido', to: 'PedidoPwa' },
            { icon: 'mdi-format-list-text', title: 'Lista Pedidos', to: 'ListaPedidos' },
            { icon: 'mdi-text-box-search', title: 'Revisão Orçamento', to: 'RevisaoOrcamento' },
            { icon: 'mdi-reminder', title: 'Totem', to: 'Transacoes/avaliacoes/totem' },
            { icon: 'mdi-currency-usd', title: 'Recebimento Caixa', to: 'Transacoes/caixa/recebcaixa' },
            {
              icon: 'mdi-archive-settings',
              title: 'Expedição',
              group: '/v1',
              children: [
                // { icon: 'mdi-plus', title: 'Nova Requisição', to: 'Expedicao/novaRequicao' },
                // { icon: 'mdi-plus', title: 'Separar Simples', to: 'Expedicao/SeparaMaterialSimples' },
                { icon: 'mdi-plus', title: 'Separar Material', to: 'Expedicao/SepararItemPedido' },
                { icon: 'mdi-plus', title: 'Recebimento Rota', to: 'Expedicao/RecebeCaixaRota' },
                { icon: 'mdi-plus', title: 'Devolução Material', to: 'Expedicao/DevolucaoMaterial' },
              ],
            },
            {
              icon: 'mdi-archive-settings',
              title: 'Nota Fiscal(NFe)',
              group: '/v1',
              children: [
                { icon: 'mdi-plus', title: 'Ciençia da Operação', to: 'Notafiscal/Manifestacaodestinatario/mandestinatario' },
              ],
            },
            {
              icon: 'mdi-palette-swatch',
              title: 'Estoque',
              group: '/v1',
              children: [
                { icon: 'mdi-plus', title: 'Ajuste Físico', to: 'Transacoes/Estoque/AjusteFisico' },
              ],
            },
          ],
        },
        {
          icon: 'mdi-card-search-outline',
          title: 'Consultas',
          group: '/v1',
          children: [
            { icon: 'mdi-car-search-outline', title: 'Pix de Rotas', to: 'Consultas/PixRotaConsulta' },
          ],
        },
        {
          icon: 'mdi-chart-box-outline',
          title: 'Relatórios',
          group: '/v1',
          children: [
            { icon: 'mdi-currency-usd', title: 'Posição Físico', to: 'PosicaoFisico' },
            {
              icon: 'mdi-archive-settings',
              title: 'Estoque',
              group: '/v1',
              children: [
                // { icon: 'mdi-plus', title: 'Nova Requisição', to: 'Expedicao/novaRequicao' },
                { icon: 'mdi-file-chart', title: 'Acompanhamento Custo', to: 'Relatorio/Estoque/EstoqueAcompanhamento' },
              ],
            },
          ],
        },
        {
          icon: 'mdi-cogs',
          title: 'Configurações',
          group: '/v1',
          children: [
            { icon: 'mdi-plus', title: 'Usuários', to: 'ListaUsuario' },
          ],
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('SoftFamily'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
